import { HttpClient, HttpHeaders,HttpParams  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/Rx';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DataService {
  apiUrl = "https://dev.braceinnovations.co.za/api/";
 // apiUrl = "https://localhost:44367/api/" ;
  IP : any;

  constructor(private fb: FormBuilder, private httpClient: HttpClient) { }
 
  WiFiform = this.fb.group({
    FullName: ['', Validators.required],
    Email: ['', Validators.required],
    Gender: ['', Validators.required],
    Race: ['', Validators.required],
    phoneNo: ['', Validators.required],
    ageGroup: ['', Validators.required],
  });


  regWiFi(ip : any) {

    var body = {
      FullName: this.WiFiform.value.FullName,
      Email: this.WiFiform.value.Email,
      Gender: this.WiFiform.value.Gender,
      Race: this.WiFiform.value.Race,
      phoneNo: this.WiFiform.value.phoneNo,
      ageGroup : this.WiFiform.value.ageGroup,
      routerIP : ip    
    };
    return this.httpClient.post(this.apiUrl + "User/AddRouterUser", body);
  }
  
  Registerform = this.fb.group({
    FullName: ['', Validators.required],
    Email: ['', Validators.required],
    Gender: ['', Validators.required],
    Race: ['', Validators.required],
    PhoneNumber: ['', Validators.required],
    IdNumber: ['', Validators.required],
    Role: [''],
    Province: ['', Validators.required],
    City: ['', Validators.required]
  });




  Register() {
    var body = {
      FullName: this.Registerform.value.FullName,
      Email: this.Registerform.value.Email,
      Gender: this.Registerform.value.Gender,
      Race: this.Registerform.value.Race,
      PhoneNumber: this.Registerform.value.PhoneNumber,
      IdNumber : this.Registerform.value.IdNumber,
      Role : "Student",
      Province : this.Registerform.value.Province,
      City : this.Registerform.value.City,
    
    };
    return this.httpClient.post(this.apiUrl + "User/Register", body);
  }

  Genders() {
    return this.httpClient.get<any>(this.apiUrl + "Data/GetGender");
  }

  Races() {
    return this.httpClient.get<any>(this.apiUrl + "Data/GetRace");
  }

  Provinces() {
    return this.httpClient.get<any>(this.apiUrl + "Data/GetProvince");
  }

}
