
  <div class="my-login-page">
    <section class="h-100">
      <div class="container h-100">
        <div class="row justify-content-md-center h-100">
          <div class="card-wrapper">
            <div class="d-flex justify-content-center">
              <img style=" border-radius: 8px; margin-top:1px;" src="../../assets/img/lg.png" alt="logo">
            </div>
            <div class="card fat" style="margin-top:10px">
              <div class="card-body">
                <div class="d-flex justify-content-center">
                  <img src="../../assets/img/lg1.png" alt="logo">
                </div>

                <form  style="color: white;" [formGroup]="service.WiFiform" autocomplete="off" (submit)="onSubmit()">
                  <div class="row">
                    <div class="form-group col-md-4">
                      <label style="color: black;">FullName</label>
                      <input class="form-control" formControlName="FullName">
                      <label class="text-danger"
                        *ngIf="service.WiFiform.get('FullName').touched && service.WiFiform.get('FullName').errors?.required">This
                        field is mandatory.</label>
                    </div>

                    <div class="form-group col-md-4">
                      <label style="color: black;">Phone Number</label>
                      <input type="number" class="form-control" formControlName="phoneNo" required>
                      <label class="text-danger"
                      *ngIf="service.WiFiform.get('phoneNo').touched && service.WiFiform.get('phoneNo').errors?.required">This
                      field is mandatory.</label>
                    </div>
                    
  
                    <div class="form-group col-md-4">
                      <label style="color: black;">Age Group</label>
                      <select class="form-select"  formControlName="ageGroup">
                        <option selected>Select</option>
                        <option *ngFor="let info of age">
                          {{info}}
                        </option>
                      </select>
                      <label class="text-danger"
                        *ngIf="service.WiFiform.get('ageGroup').touched && service.WiFiform.get('ageGroup').errors?.required">This
                        field is mandatory.</label>
                    </div>
  
                  </div>
  
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label style="color: black;">Email</label>
                      <input class="form-control" formControlName="Email">
                      <label class="text-danger"
                        *ngIf="service.WiFiform.get('Email').touched && service.WiFiform.get('Email').errors?.required">This
                        field is mandatory.</label>
                    </div>
                    
                    <div class="form-group col-md-3">
                      <label style="color: black;">Gender</label>
                      <select class="form-select"  formControlName="Gender">
                        <option selected>Select</option>
                        <option *ngFor="let info of genders">
                          {{info.gender}}
                        </option>
                      </select>
                      <label class="text-danger"
                        *ngIf="service.WiFiform.get('Gender').touched && service.WiFiform.get('Gender').errors?.required">This
                        field is mandatory.</label>
                    </div>
  
                    <div class="form-group col-md-3">
                      <label style="color: black;">Race</label>
                      <select class="form-select"  formControlName="Race">
                        <option selected>Select</option>
                        <option *ngFor="let info of races">
                          {{info.race}}
                        </option>
                      </select>
                      <label class="text-danger"
                        *ngIf="service.WiFiform.get('Race').touched && service.WiFiform.get('Race').errors?.required">This
                        field is mandatory.</label>
                    </div>
  
                    
                  </div>
  
  
                   <hr>
                  <div class="text-center">
                    <div class="form-group col-md-8 offset-md-2">
                       <button type="submit" class="btn btn-lg btn-success" style="margin:10px;" [disabled]="service.WiFiform.invalid">Access WiFi</button>
                    </div>
                  </div>
                </form>

              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

  </div>


