import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {RegisterComponent} from './register/register.component';
import {ELearningComponent} from './e-learning/e-learning.component';



const routes: Routes = [{path: '', redirectTo: '/register', pathMatch: 'full' },
{ path: 'register', component: RegisterComponent},
/*{ path: 'elearning', component: ELearningComponent}*/] ;

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
