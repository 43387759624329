import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service' ;
import { Router } from '@angular/router';

@Component({
  selector: 'app-e-learning',
  templateUrl: './e-learning.component.html',
  styleUrls: ['./e-learning.component.css']
})
export class ELearningComponent implements OnInit {
  genders ;
  races ;
  provinces ;
  age = ['Below 12','12-17','17-25','26-31','Above 31'] ;


  constructor(public service:DataService,public router: Router) { }

  ngOnInit(): void {
    this.service.Registerform.reset();

    this.service.Genders().subscribe(
      res => {
        this.genders = res;
        //console.log(res);
      },
      err => {
        console.log(err);
      },
    );

    this.service.Races().subscribe(
      res => {
        this.races = res;
        //console.log(res);
      },
      err => {
        console.log(err);
      },
    );

    this.service.Provinces().subscribe(
      res => {
        this.provinces = res;
        //console.log(res);
      },
      err => {
        console.log(err);
      },
    );

    
  }

  onSubmit() {
    this.service.Register().subscribe(
      (res: any) => {
        window.location.href = 'https://www.google.com/';
      }
    );
  }

  goBack(){
    window.location.href = 'https://iotsystem.braceinnovations.co.za/';
  }


}
